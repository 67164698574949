@import "../../styles/helpers";

.label {
    color: #000000;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    letter-spacing: -0.02em;
    text-align: left; }

.input {
    width: 100%;
    height: 48px;
    padding: 0 14px;
    border-radius: 12px;
    border: 2px solid $neutrals6;
    background: none;
    @include poppins;
    @include caption-bold-1;
    color: $neutrals2;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3;
        color: $neutrals8; }
    @include placeholder {
        color: $neutrals4; }
    &:focus {
        border-color: $neutrals4; } }
