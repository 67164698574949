@import "../../../styles/helpers";

.item {
    margin-top: 25px;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; }
    &:not(:last-child) {
        margin-bottom: 16px; } }

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    margin-right: 16px;
    & > img {
        width: 85%;
        height: 85%;
        border-radius: 50%;
        object-fit: cover; } }

.reward {
    position: absolute;
    right: -5px;
    bottom: -5px;
    img {
        max-width: 25px; } }

.details {
    flex-grow: 1; }

.position {
    color: $neutrals4;
    text-align: left; }

.name {
    font-weight: 500;
    text-align: left; }
