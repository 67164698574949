@import "../../styles/helpers";

.section {
    overflow: hidden;
    padding: 50px 60px 96px;
    @include t {
        padding: 50px 0px 96px; } }

.wrapper {
    position: relative; }

.title {
    margin-bottom: 64px;
    @include m {
        margin-bottom: 48px; } }

.inner {
    margin: 0 -16px;
    @include m {
        margin: 0 -8px; } }

.card {
    width: 100%;
    margin: 32px 16px 0px;
    padding: 12px;
    box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12);
    border-radius: 20px;
    @include dark {
        background: $neutrals2; } }

.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }

.mt {
    margin-top: 32px;
    margin-top: 60px;
    width: 100%;
    max-width: 330px; }
