@import "../../../styles/helpers";

.head {
    max-width: 600px;
    margin: 0 auto 128px;
    text-align: center;
    @include x {
        margin-bottom: 112px; }
    @include m {
        margin-bottom: 64px; } }

.center {
    align-items: center;
    text-align: center;
    justify-content: center;
    align-content: center; }

.start {
    justify-content: center;
    align-content: flex-start;
    flex-grow: 0;
    margin: 0; }

.stage {
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: -64px;
    @include a {
        margin-bottom: 12px; }
    @include m {
        margin-bottom: 12px; }
    @include s {
        margin-bottom: 12px; }
    @include t {
        margin-bottom: 12px; } }

.galleryTitle {
    font-size: 20px !important;
    text-align: center;
    padding-top: 64px;
    @include body-bold-1;
    @include m {
        margin-bottom: 3px;
        font-size: 16px; } }


.title {
    font-family: DM Sans;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-align: left;
    margin-bottom: 16px; }

.hairline {
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 12px;
    color: #777E91; }

.wrapper {
    flex: 2;
    align-items: center;
    flex-direction: column;
    margin: 0 12px; }

.userWrapper {
    flex: 1; }

.row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: flex-start;
    @include t {
        display: block; } }

.player {
    flex-grow: 1;
    @include t {
        max-width: 768px;
        margin: 0 auto; } }

.details {
    flex-shrink: 1;
    width: 360px;
    //margin-left: 128px
    padding-bottom: 80px;
    @include x {
        margin-left: 64px; }
    @include d {
        width: 304px;
        margin-left: 32px;
        padding-bottom: 64px; }
    @include t {
        width: 100%;
        margin: 48px 0 0;
        padding-bottom: 80px; }
    @include m {
        margin-top: 32px; } }

.subtitle {
    margin-bottom: 20px;
    @include d {
        font-size: 48px; } }

.line {
    display: flex;
    margin: 0 -16px 40px;
    @include d {
        margin-bottom: 24px; } }

.item {
    display: flex;
    align-items: center;
    flex: 0 0 calc(50% - 32px);
    width: calc(50% - 32px);
    margin: 0 16px; }

.avatar,
.icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 8px; }

.avatar {
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $green;
    border-radius: 50%;
    svg {
        fill: $neutrals8; } }

.description {
    flex-grow: 1; }

.category {
    @include caption-2;
    color: $neutrals4; }

.text {
    font-weight: 500; }

.wrap {
    max-width: 500px;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    padding: 30px 16px;
    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border: 2px solid $neutrals6;
    border-radius: 24px;
    text-align: center;
    @include d {
        margin-bottom: 8px !important;
        padding: 24px 16px; }
    @include t {
        margin-bottom: 16px !important; }
    @include m {
        margin-bottom: 16px; }
    @include a {
        max-width: 350px;
        margin-bottom: 16px !important; }
    @include dark {
        background: $neutrals2;
        border-color: $neutrals2; } }

.info {
    margin-bottom: 18px;
    @include body-bold-2; }

.info2 {
    margin-bottom: 18px;
    @include body-bold-2;
    text-align: center; }


.currency {
    @include dm-sans;
    font-size: 36px;
    line-height: (56/48);
    letter-spacing: -.02em; }

.price {
    margin-bottom: 24px;
    @include body-bold-1;
    color: $neutrals4; }

.timer {
    display: flex;
    justify-content: center;
    margin-top: 8px; }

.box {
    flex-shrink: 0;
    width: 64px;
    &:not(:last-child) {
        margin-right: 20px; } }

.number {
    @include dm-sans;
    font-size: 32px;
    line-height: (40/32);
    letter-spacing: -.01em; }

.time {
    @include body-bold-2;
    color: $neutrals4; }

.btns {
    display: flex;
    flex-direction: column;
    .button {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 8px; } } }


.card {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 24px;
    max-width: 500px;
    max-height: 500px; }

.preview {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-grow: 1;
    margin-right: 10px;
    img {
        max-width: 700px;
        max-height: 700px;
        border-radius: 16px;
        @include d {
            max-width: 500px;
            max-height: 500px; }
        @include s {
            max-width: 350px;
            max-height: 350px; }
        @include a {
            max-width: 350px;
            max-height: 350px; }
        @include m {
            max-width: 350px;
            max-height: 350px; } } }

.gridLayout {
    display: grid;
    width: 100%;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    margin-top: 24px;
    margin-bottom: 64px;
    @include t {
        grid-template-columns: 1fr 1fr; }

    @include m {
        grid-template-columns: 1fr 1fr; } }


.flex-grid {
  display: flex;
  flex-wrap: wrap;
  height: 500px; }

.grid-item {
  flex: 1 1 calc(100% - 50px);
  background: #F90;
  border-top: solid 1px #000;

  &:nth-child(odd) {
    background: #F00;
    flex: 0 0 50px; } }

.about {
    margin-bottom: 32px;
    @include body-2;
    color: $neutrals4; }

.activations {
    margin-bottom: 8px;
    @include body-1;
    color: $neutrals4; }

.activationPreview {
    position: relative;
    flex-shrink: 0;
    width: 96px;
    height: 96px;
    display: flex;
    @include m {
        width: 64px;
        height: 64px;
        margin-right: 16px; }
    & > img {
        width: 75%;
        height: 75%;
        border-radius: 50%;
        object-fit: cover;
        margin-top: 10px; } }

.activationHead {
    text-align: left;
    @include body-2;
    color: $neutrals3;
    font-size: 16px;
    margin-bottom: 16px;
    @include m {
        font-size: 14px; }
    @include dark {
        color: $neutrals6; } }

.activationDetails {
    flex-grow: 1;
    text-align: left; }

.activationSubtitle {
    font-size: 20px !important;
    @include body-bold-1;
    @include m {
        margin-bottom: 3px;
        font-size: 16px; } }

.activationDescription {
    @include body-2;
    color: $neutrals3;
    font-size: 18px;
    @include m {
        font-size: 18px; }
    @include dark {
        color: $neutrals6; } }

.date1 {
    @include caption-bold-2;
    color: $neutrals4; }

.activationItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 64px 16px 0px;
    border-radius: 20px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    transition: background .2s;
    @include m {
        padding-right: 32px; }
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 24px;
        width: 12px;
        height: 12px;
        transform: translateY(-50%);
        transition: all .2s;
        @include m {
            right: 12px; } }
    //&:hover
    ///   background: $neutrals7
    ////  +dark
    //        background: $neutrals2
    ///   &:before
    ///       transform: translateY(-50%) scale(0)
    ////  &:after
    // //     opacity: 1
    &:not(:last-child) {
        margin-bottom: 16px; } }

.socials {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    left: 100px;
    @include m {
        flex-direction: row !important;
        left: 0px; }
    @include t {
        flex-direction: row !important;
        left: 0px; } }

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    transition: border-color .2s;
    margin-top: 8px;
    margin-bottom: 16px;
    @include dark {
        border-color: $neutrals3; }
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        border-color: $blue;
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 16px; }
    @include m {
        margin-top: -16px; } }


.icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: $neutrals6;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    .loader {
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: $neutrals8;
        @include dark {
            background: $neutrals1; } } }
