@import "../../styles/helpers";

.footer {
    border-top: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.row {
    display: flex;
    padding: 80px 0 48px;
    border-bottom: 1px solid $neutrals6;
    @include t {
        display: block; }
    @include m {
        padding: 64px 0 48px; }
    @include dark {
        border-color: $neutrals3; } }

.col {
    &:first-child {
        flex-grow: 1;
        padding-right: 32px;
        @include t {
            position: relative;
            margin-bottom: 32px;
            padding: 0; } }
    &:nth-child(2) {
        display: flex;
        flex-shrink: 0;
        width: 352px;
        @include d {
            width: 276px; }
        @include t {
            width: auto;
            margin-bottom: 32px; }
        @include m {
            display: block;
            border-width: 1px 0;
            border-style: solid;
            border-color: $neutrals6;
            @include dark {
                border-color: $neutrals3; } } }
    &:nth-child(3) {
        flex-shrink: 0;
        width: 384px;
        padding-left: 32px;
        @include d {
            width: 304px; }
        @include t {
            width: 100%;
            padding: 0; } } }

.logo {
    display: flex;
    position: relative;
    flex-direction: row;
    z-index: 12;
    flex-shrink: 0;
    margin-right: 24px;
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
    @include d {
        margin-right: auto; }
    img {
        width: 100%; }
    h2 {
        align-self: center;
        color: #000000; } }

.info {
    max-width: 256px;
    @include body-1;
    @include d {
        font-size: 16px; } }

.version {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @include t {
        position: absolute;
        top: 5px;
        right: 0;
        margin: 0; } }

.details {
    margin-right: 16px;
    @include caption-2;
    @include m {
        margin-right: 10px; } }

.category {
    margin-bottom: 40px;
    @include body-bold-2;
    @include t {
        margin-block-end: 24px; } }

.text {
    margin-bottom: 24px; }

.foot {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    margin-top: 24px;
    @include caption-2;
    @include m {
        display: block;
        padding: 24px 0 32px; } }

.copyright {
    color: $neutrals4;
    @include m {
        text-align: center; } }

.note {
    @include m {
        display: none; }
    a {
        margin-left: 16px;
        font-weight: 600;
        color: $blue;
        transition: color .2s;
        &:hover {
            color: darken($blue, 10); } } }


.socials {
    display: flex;
    flex-direction: row; }

.social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    transition: border-color .2s;
    margin-top: 10px;
    @include dark {
        border-color: $neutrals3; }
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        border-color: $blue;
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 16px; } }

.icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: $neutrals6;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    .loader {
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: $neutrals8;
        @include dark {
            background: $neutrals1; } } }
